import _delay from 'lodash/delay';
import { action, computed, observable, runInAction } from 'mobx';
import subscriptionStore from '../../0-common/store/SubscriptionStore';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import storeFactory from '../../0-common/utils/StoreFactory';

export class CreateStore {
	@observable isCreateViewOpen = false;
	@observable isLoading = false;
	@observable createItemState = {
		Name: '',
		Type: '',
		Category: '',
	};
	@observable emptyNameError = false;
	@observable emptyCategoryError = false;
	@observable limitReachedError = false;

	@observable emptyMobileNumberError = false; //m
	@observable emptyEmailError = false; //m
	@observable validStatus = {
		//m
		emailMsg: '',
		nameMsg: '',
		mobileNumberMsg: '',
		errorMsg: '',
	};
	@observable customerExists = false; //m

	itemStoreRef = null;

	@computed
	get TypeOfCreate() {
		return this.createItemState.Type;
	}

	get SubscriptionStore() {
		return subscriptionStore;
	}

	init(createTypeId) {
		this.createItemState.Type = createTypeId;
		this.itemStoreRef = storeFactory.fetchStore(createTypeId);
		console.log(this.itemStoreRef);
	}
	//m
	initCustomer() {
		this.createItemState.Email = '';
		this.createItemState.MobileNumber = '';
		this.createItemState.Type = 'USER';
		this.itemStoreRef = storeFactory.fetchStore('USER');
		console.log(this.itemStoreRef);
	}

	@action
	updatePropertyForCreate(propertyName, propertyValue) {
		this.createItemState[propertyName] = propertyValue;
	}
	@action.bound
	resetVerifyStatus() {
		this.emptyNameError = false;
		this.emptyEmailError = false;
		this.emptyMobileNumberError = false;
		this.customerExists = false;
		this.validStatus.errorMsg = '';
	}

	isDataValidate() {
		if (this.createItemState.Name === '') {
			this.emptyNameError = true;
			return false;
		} else if (this.createItemState.Category === '') {
			this.emptyCategoryError = true;
			return false;
		} else {
			return true;
		}
	}

	isDataValidForCustomer() {
		const emailCheckRegex =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isEmailValid = emailCheckRegex.test(this.createItemState.Email);
		const mobileNumberCheckRegex = /^\d{10}$/;
		const isMobileNumberValid = mobileNumberCheckRegex.test(
			this.createItemState.MobileNumber
		);
		//Name
		if (this.createItemState.Name === '') {
			this.emptyNameError = true;
			this.validStatus.nameMsg = 'Please enter your name.';
			return false;
		} else if (this.createItemState.Email === '') {
			//Email
			this.emptyEmailError = true;
			this.validStatus.emailMsg = 'Please enter your email address.';
			return false;
		} else if (!isEmailValid) {
			this.emptyEmailError = true;
			this.validStatus.emailMsg = 'Please enter a valid email address.';
			return false;
		} else if (this.createItemState.MobileNumber === '') {
			this.emptyMobileNumberError = true;
			this.validStatus.mobileNumberMsg =
				'Please enter your mobile number.';
			return false;
		} else if (!isMobileNumberValid) {
			this.emptyMobileNumberError = true;
			this.validStatus.mobileNumberMsg =
				'Mobile number must be valid and contain 10 digits.';
			return false;
		} else {
			return true;
		}
	}

	resetCreateItemState() {
		this.createItemState = {
			Name: '',
			Type: '',
			Category: '',
		};
		this.emptyNameError = false;
		this.emptyCategoryError = false;
		this.emptyEmailError = false; //m
		this.emptyMobileNumberError = false; //m
		console.log(this.createItemState);
	}

	/*handleItemCreateAction = (e) => {
  		console.log(e.target);
		const actionName = e.target.getAttribute("action");
		console.log("Create Action > "+actionName)
		if(actionName)
			emitter.emit(actionName, actionName, false);
		else
			emitter.emit(EVENTS.ITEM.CREATE.CANCEL, EVENTS.ITEM.CREATE.CANCEL);
	}*/

	/*handleCardPredicateCreateAction = (predicate, e) => {
		const actionName = e.target.getAttribute("action");
		if(actionName)
			emitter.emit(actionName, actionName, true, predicate);
		else
			emitter.emit(EVENTS.ITEM.CREATE.CANCEL, EVENTS.ITEM.CREATE.CANCEL);
	}*/

	@action
	async createItemAction(mode, createTypeId) {
		console.log('Create Action : ' + mode);
		switch (mode) {
			case EVENTS.ITEM.CREATE.OPEN: {
				this.isCreateViewOpen = true;
				this.init(createTypeId);
				break;
			}
			case EVENTS.ITEM.CREATE.DONE: {
				this.isLoading = true;
				if (this.isDataValidate()) {
					const createdItem = await this.itemStoreRef.createItem(
						this.createItemState
					);
					runInAction('CreateStore > On Create Done', () => {
						this.resetCreateItemState();
						this.emptyNameError = false;
						this.emptyCategoryError = false;
						this.emptyEmailError = false;
						this.emptyMobileNumberError = false;
						storeFactory.NavigationStore.changeModule(
							createdItem.DATA.Type
						);
						emitter.emit(EVENTS.ITEM.CLOSE);
						this.isCreateViewOpen = false;
						_delay(
							() =>
								emitter.emit(
									EVENTS.ITEM.OPEN,
									EVENTS.ITEM.OPEN,
									createdItem.DATA.uid,
									createdItem.DATA.Type,
									createdItem.DATA.Category,
									createdItem.DATA.Type
								),
							500
						);
						//storeFactory.NavigationStore.changeModule(createdItem.DATA.Type);
						//this.itemStoreRef.closeItem();
						//this.itemStoreRef.executeItemAction(EVENTS.ITEM.OPEN, createdItem.DATA.uid, createdItem.DATA.Type, createdItem.DATA.Category)
						//this.itemStoreRef.openItem()
					});
				}
				break;
			}
			case EVENTS.ITEM.CREATE.CANCEL: {
				this.resetCreateItemState();
				this.isCreateViewOpen = false;
				this.limitReachedError = false;
				break;
			}
		}
	}

	@action
	async createCustomerAction(mode) {
		console.log('Create Action : ' + mode);

		switch (mode) {
			case EVENTS.ITEM.CREATE.OPEN: {
				this.isCreateViewOpen = true;
				this.initCustomer();
				break;
			}
			case EVENTS.ITEM.CREATE.DONE: {
				this.isLoading = true;
				if (this.isDataValidForCustomer()) {
					//todo
					let patch = [];
					const addEmailPatch = {
						op: 'add',
						path: '/Email',
						value: this.createItemState.Email,
					};
					const addMobileNumberPatch = {
						op: 'add',
						path: '/MobileNumber',
						value: this.createItemState.MobileNumber,
					};
					patch.push(addEmailPatch);
					patch.push(addMobileNumberPatch);
					this.createItemState.PATCH = patch;
					const createdItem = await this.itemStoreRef.createItem(
						this.createItemState
					);
					if (createdItem.EXCEPTION) {
						if (
							createdItem.EXCEPTION.ClassName ==
							'UserAlreadyExistsException'
						) {
							runInAction(
								'CreateStore > On Create Error !! Customer Already Exist !!',
								() => {
									this.customerExists = true;
									this.isLoading = false;
									this.isCreateViewOpen = true;
									this.validStatus.errorMsg =
										createdItem.EXCEPTION.Message;
									//storeFactory.ErrorStore.log(error, "Couldn't perform Create - Customer Already Exists", "On executeCreateItemAction");
								}
							);
						}
					} else {
						runInAction('CreateStore > On Create Done', () => {
							this.resetCreateItemState();
							this.emptyNameError = false;
							this.emptyEmailError = false;
							this.emptyMobileNumberError = false;
							storeFactory.NavigationStore.changeModule(
								'CUSTOMERS'
							);
							emitter.emit(EVENTS.ITEM.CLOSE);
							this.isCreateViewOpen = false;

							_delay(
								() =>
									emitter.emit(
										EVENTS.ITEM.OPEN,
										EVENTS.ITEM.OPEN,
										createdItem.DATA.uid,
										createdItem.DATA.Type,
										createdItem.DATA.Category
									),
								500
							);

							//storeFactory.NavigationStore.changeModule(createdItem.DATA.Type);
							//this.itemStoreRef.closeItem();
							//this.itemStoreRef.executeItemAction(EVENTS.ITEM.OPEN, createdItem.DATA.uid, createdItem.DATA.Type, createdItem.DATA.Category)
							//this.itemStoreRef.openItem()
						});
					}
				}
				break;
			}
			case EVENTS.ITEM.CREATE.CANCEL: {
				this.resetCreateItemState();
				this.isCreateViewOpen = false;
				this.limitReachedError = false;
				break;
			}
		}
	}

	@action
	async executeCreateItemAction(mode, createTypeId) {
		try {
			console.log('Create Action for : ' + createTypeId);
			switch (createTypeId) {
				case 'USER':
				case 'CUSTOMERS': {
					await this.createCustomerAction(mode, createTypeId);
					break;
				}
				default:
					await this.createItemAction(mode, createTypeId);
					break;
			}
		} catch (error) {
			if (this.checkLimitReachedError(error)) {
				runInAction(
					'CreateStore > On Create Error !! Limit Reached !!',
					() => {
						this.limitReachedError = true;
						this.isLoading = false;
						this.isCreateViewOpen = false;
						//storeFactory.ErrorStore.log(error, "Couldn't perform Create - Limit Reached", "On executeCreateItemAction");
					}
				);
			} else {
				runInAction('CreateStore > On Create Error !! ERROR !!', () => {
					this.resetCreateItemState();
					this.isLoading = false;
					this.isCreateViewOpen = false;
					storeFactory.ErrorStore.log(
						error,
						"Couldn't perform Create",
						'Create Store > On executeCreateItemAction : ' +
							this.createItemState
					);
				});
			}
		} finally {
			//_delay( () => this.isLoading = false, 1000);
			this.isLoading = false;
		}
	}

	checkLimitReachedError(error) {
		let errorMsg = '';
		if (error.response) {
			if (error.response.data) {
				const errorData = error.response.data;
				if (errorData.EXCEPTION) {
					if (
						errorData.EXCEPTION.ClassName ===
						'LimitReachedException'
					) {
						return true;
					}
				}
			}
		}
		return false;
	}
}

export default new CreateStore();
