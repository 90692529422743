import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { emitter, EVENTS } from '../../../0-common/utils/EventEmitter';
import AddItemContainer from './AddItemContainer';


const STORE_SUBJECT_TYPE = "CATALOGS";

@inject('storeFactory')
@observer
export default class AddToCatalogContainer extends Component{
	
	emitterBindList = [];
	transactional_emitterBindList = [];
	//UI Status 
	@observable isOpenAddToCatalogBox = false;

	constructor(props){
		super(props);
		this.itemStore = props.itemStore;
		this.bindEvents();
	}

	bindEvents = () => {
		this.emitterBindList.push(emitter.on(EVENTS.ITEM.ADDTOCATALOG.OPEN, this.handleAddToCatalogActions_OPEN));
	}

	bindTransactionalEvents = () => {
		this.transactional_emitterBindList.push(emitter.on(EVENTS.ITEM.ADDTOCATALOG.CANCEL, this.handleAddToCatalogActions_CANCEL));
		this.transactional_emitterBindList.push(emitter.on(EVENTS.ITEM.ADDTOCATALOG.DONE, this.handleAddToCatalogActions_DONE));
	}

	unBindEvents = () => {
		this.emitterBindList.map( (unbindRef) => {
			unbindRef();
		});
		this.emitterBindList = [];
	}

	unBindTransactionalEvents = () => {
		this.transactional_emitterBindList.map( (unbindRef) => {
			unbindRef();
		});
		this.transactional_emitterBindList = [];
	}

	handleAddToCatalogActions_OPEN = () => {
		emitter.set("CATALOGS");
		this.bindTransactionalEvents();
		this.props.storeFactory.NavigationRepository.set("AddItem_Catalog", STORE_SUBJECT_TYPE);
		this.isOpenAddToCatalogBox = true;
	}

	handleAddToCatalogActions_CANCEL = () => {	
		emitter.unset();
		this.props.storeFactory.NavigationRepository.unset();
		this.unBindTransactionalEvents();
		this.itemStore.toggleAllSelectionTo(false);
		this.itemStore.resetSecActionStatus();
		this.isOpenAddToCatalogBox = false;
	}

	handleAddToCatalogActions_DONE = () => {
		const selectedProductsToBeAddedInCatalog = this.itemStore.getSelectedItems();
		const addItemContainer_catalogStore = this.props.storeFactory.fetchStore(STORE_SUBJECT_TYPE);
		addItemContainer_catalogStore.executeAddToCatalogAction(selectedProductsToBeAddedInCatalog.selectdItem, this.itemStore.SubjectInfo.Type);
		emitter.unset();
		this.props.storeFactory.NavigationRepository.unset();
		this.unBindTransactionalEvents();
		this.itemStore.toggleAllSelectionTo(false);
		this.itemStore.resetSecActionStatus();
		this.isOpenAddToCatalogBox = false;
	}

	componentWillUnmount(){
		this.unBindEvents();
	}

	render(){

		if(this.isOpenAddToCatalogBox){
			return (
				<AddItemContainer
					storeType={STORE_SUBJECT_TYPE}
					onAdd={this.handleAddToCatalogActions_DONE}
					onCancel={this.handleAddToCatalogActions_CANCEL} />
			);
		} else {
			return null;
		}
	}
}