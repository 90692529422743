import { inject, observer } from 'mobx-react';
import React, { Component, lazy } from 'react';
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { Container, Label } from "semantic-ui-react";

function retry(fn, retriesLeft = 5, interval = 500) {
	return new Promise((resolve, reject) => {
	  fn()
		.then(resolve)
		.catch((error) => {
			console.warn("Lazy fetch failed : ");
			console.warn(error);
			setTimeout(() => {
				if (retriesLeft === 1) {
				// reject('maximum retries exceeded');
				reject(error);
				return;
				}
				console.info("Lazy fetch RETRYING.......");
				// Passing on "reject" is the important part
				retry(fn, retriesLeft - 1, interval).then(resolve, reject);
			}, interval);
		});
	});
}

const LoginScreen 				= lazy(() => retry(() => import(/* webpackChunkName: "loginscreen" */ '../screen/LoginScreen')));
const HomeScreen 				= lazy(() => retry(() => import(/* webpackChunkName: "homescreen" */ '../screen/HomeScreen')));
const UserScreen 				= lazy(() => retry(() => import(/* webpackChunkName: "userscreen" */ '../screen/UserScreen')));
const ServerUnderMaintScreen 	= lazy(() => retry(() => import(/* webpackChunkName: "serverUnderMaintenance" */ '../../0-common/screen/ServerUnderMaintenanceScreen')));
const SubscriptionScreen 		= lazy(() => retry(() => import(/* webpackChunkName: "subscriptionscreen" */ '../screen/SubscriptionScreen')));

@inject("storeFactory", "userStore")
@observer
export default class AppContainer extends Component {

	renderSiteLabel(){
		const siteMode = process.env.siteMode;
		if(siteMode === "DEMO"){
			return(
				<Label color="blue" className="siteLabel">
					Demo System
				</Label>
			);
		} else {
			return null;
		}
	}

	renderHomeScreen(){
		let supportNumber = this.props.storeFactory.ModuleStore.SupportNumber;
		if(supportNumber && !supportNumber.startsWith("+91")){
			supportNumber = `+91${supportNumber}`;
		}
		return (<Container fluid className="App">
					{this.renderSiteLabel()}
					<HomeScreen />
					<WhatsAppWidget phoneNumber={supportNumber} />
				</Container>);
	}

	renderLoginScreen(){
		return (<Container fluid className="App">
					{this.renderSiteLabel()}
					<LoginScreen />
				</Container>);
	}

	renderResetPasswordScreen(){
		return (<Container fluid className="App">
					{this.renderSiteLabel()}
					<ResetPasswordScreen />
				</Container>);
	}

	renderUserScreen(){
		return (<Container fluid className="App">
					{this.renderSiteLabel()}
					<UserScreen />
				</Container>);
	}

	renderSubscriptionScreen(){
		return (<Container fluid className="App">
					{this.renderSiteLabel()}
					<SubscriptionScreen />
				</Container>);
	}

	renderServerUnderMaintenanceScreen(){
		return (<Container fluid className="NoApp">
					{this.renderSiteLabel()}
					<ServerUnderMaintScreen />
				</Container>);
	}

	render() {
		const navigationStore = this.props.storeFactory.NavigationStore;
		const screen = navigationStore.currentScreen.screenName;
		console.log("AppContainer rendering.... : "+ screen);
		switch(screen){
			case navigationStore.SCREEN.HOME  : return this.renderHomeScreen();
			case navigationStore.SCREEN.LOGIN : return this.renderLoginScreen();
			case navigationStore.SCREEN.RESETPASSWORD : return this.renderResetPasswordScreen();
			case navigationStore.SCREEN.USER : return this.renderUserScreen();
			case navigationStore.SCREEN.SUBSCRIPTION : return this.renderSubscriptionScreen();
			case navigationStore.SCREEN.SERVER_UNDER_MAINTENANCE : return this.renderServerUnderMaintenanceScreen();
			default : return this.renderLoginScreen();
		}
	}
}