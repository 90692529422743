import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { emitter, EVENTS } from '../../../0-common/utils/EventEmitter';
import AddItemContainer from './AddItemContainer';


const STORE_SUBJECT_TYPE = "COLLECTIONS";

@inject('storeFactory')
@observer
export default class AddToCollectionContainer extends Component{
	
	emitterBindList = [];
	transactional_emitterBindList = [];
	//UI Status 
	@observable isOpenAddToCollectionBox = false;

	constructor(props){
		super(props);
		this.itemStore = props.itemStore;
		this.bindEvents();
	}

	bindEvents = () => {
		this.emitterBindList.push(emitter.on(EVENTS.ITEM.ADDTOCOLLECTION.OPEN, this.handleAddToCollActions_OPEN));
	}

	bindTransactionalEvents = () => {
		this.transactional_emitterBindList.push(emitter.on(EVENTS.ITEM.ADDTOCOLLECTION.CANCEL, this.handleAddToCollActions_CANCEL));
		this.transactional_emitterBindList.push(emitter.on(EVENTS.ITEM.ADDTOCOLLECTION.DONE, this.handleAddToCollActions_DONE));
	}

	unBindEvents = () => {
		this.emitterBindList.map( (unbindRef) => {
			unbindRef();
		});
		this.emitterBindList = [];
	}

	unBindTransactionalEvents = () => {
		this.transactional_emitterBindList.map( (unbindRef) => {
			unbindRef();
		});
		this.transactional_emitterBindList = [];
	}

	handleAddToCollActions_OPEN = () => {
		emitter.set("COLLECTIONS");
		this.bindTransactionalEvents();
		this.props.storeFactory.NavigationRepository.set("AddItem_Collection", STORE_SUBJECT_TYPE);
		this.isOpenAddToCollectionBox = true;
	}

	handleAddToCollActions_CANCEL = () => {	
		emitter.unset();
		this.props.storeFactory.NavigationRepository.unset();
		this.unBindTransactionalEvents();
		this.itemStore.toggleAllSelectionTo(false);
		this.itemStore.resetSecActionStatus();
		this.isOpenAddToCollectionBox = false;
	}

	handleAddToCollActions_DONE = () => {
		const selectedProductsToBeAddedInCollection = this.itemStore.getSelectedItems();
		const addItemContainer_collectionStore = this.props.storeFactory.fetchStore(STORE_SUBJECT_TYPE);
		addItemContainer_collectionStore.executeAddToCollectionAction(selectedProductsToBeAddedInCollection);
		emitter.unset();
		this.props.storeFactory.NavigationRepository.unset();
		this.unBindTransactionalEvents();
		this.itemStore.toggleAllSelectionTo(false);
		this.itemStore.resetSecActionStatus();
		this.isOpenAddToCollectionBox = false;
	}

	componentWillUnmount(){
		this.unBindEvents();
	}

	render(){
		if(this.isOpenAddToCollectionBox){
			return (
				<AddItemContainer
					storeType={STORE_SUBJECT_TYPE}
					onAdd={this.handleAddToCollActions_DONE}
					onCancel={this.handleAddToCollActions_CANCEL} />
			);
		} else {
			return null;
		}
	}
}