import { Provider } from 'mobx-react';
import React, { Component } from 'react';
import languageStore from '../0-common/store/LanguageStore';
import routerStore from '../0-common/store/RouterStore';
import ErrorBoundary from '../0-common/utils/ErrorBoundary';
import storeFactory from '../0-common/utils/StoreFactory';
import StoreRepository from '../0-common/utils/StoreRepository';
import { MediaContextProvider } from "../shop/utils/Responsive";
import AppContainer from "./container/AppContainer";
import createStore from './store/CreateStore';
import homeStore from './store/HomeStore';
import loginStore from './store/LoginStore';
import userStore from './store/UserStore';

//Collect all store for Mobx Provider {: to provide store across all View, Containers }
const storeList = {
	userStore, 
	loginStore,
	homeStore,
	createStore,
	languageStore,
	storeFactory
};

@ErrorBoundary
export default class App extends Component {

	constructor(props){
		super(props);
		storeList.storeFactory.StoreRepository = new StoreRepository();
		routerStore.startRouter();
	}

    render () {
        return (
        	<Provider {...storeList}>
				<MediaContextProvider>
	                <AppContainer />
				</MediaContextProvider>
        	</Provider>
        )
    }
}

