import { inject, observer } from 'mobx-react';
import React from 'react';
import { Container, Header, Icon, Modal } from 'semantic-ui-react';
import ItemContainer from '../../../0-common/container/ItemContainer';
import { value } from '../../../0-common/store/LanguageStore';
import _remove from 'lodash/remove';

const STORE_SUBJECT_TYPE = 'USER';

@inject('createStore', 'storeFactory', 'userStore')
@observer
export default class CustomerContainer extends ItemContainer {
	constructor(props) {
		super(props, STORE_SUBJECT_TYPE);
		this.setContainerView('CustomerTableView');
	}

	get CommonActions() {
		return ['SEARCH'];
	}

	get SpecialActions() {
		return ['REFRESH'];
	}

	get ItemActions() {
		return ['SELECT', 'DELETE'];
	}

	get BulkActions() {
		return [];
	}

	get ExcludeSortOptions() {
		return ['Name', 'CreationDate', 'LastUpdatedDate'];
	}

	//@Override
	isContainerEmpty = () => {
		return false;
	};

	getTutorialVideo() {
		return (
			/*<Embed
				id='ZgzAK6vQsT8'
				active={true}
				source='youtube'
				style={{background: "none"}} />*/
			null
		);
	}

	renderEmptyView(context) {
		return (
			<div className='emptyViewContainer'>
				<Container
					textAlign='justified'
					style={{
						width: '60%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						position: 'relative',
					}}>
					{
						<Header
							style={{
								alignSelf: 'center',
								fontWeight: '300',
							}}>
							{value(
								'lb_common_empty_container_header',
								this.props.storeFactory.ModuleStore.getModule(
									STORE_SUBJECT_TYPE
								).Name
							)}
						</Header>
					}
					{this.getTutorialVideo()}
				</Container>
			</div>
		);
	}

	renderHelpView() {
		return (
			<div>
				<Modal
					closeIcon
					open={this.showTutorial}
					trigger={
						<Icon
							link
							circular
							inverted
							className='helpIcon'
							name='info'
						/>
					}
					onClose={this.onHideTutorial}
					onOpen={this.onShowTutorial}>
					<Modal.Content style={{ padding: '1rem' }}>
						<div
							style={{
								background: 'rgb(243, 241, 241)',
								width: '100%',
							}}>
							<Container
								textAlign='justified'
								style={{
									width: '100%',
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}>
								{this.getTutorialVideo()}
							</Container>
						</div>
					</Modal.Content>
				</Modal>
			</div>
		);
	}

	componentWillUnmount() {
		this.unBindEvents();
		this.resetSortOption();
	}

	componentDidMount() {
		this.fetchListViewData();
		this.resetStatus();
	}

	render() {
		return this.renderView();
	}
}
