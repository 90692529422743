import _filter from 'lodash/filter';
import { observable, runInAction } from 'mobx';
import baby from '../../0-common/utils/baby';
import storeFactory from '../../0-common/utils/StoreFactory';
import moduleContainerFactory from '../utils/ModuleContainerFactory';

class HomeStore {

	@observable isLoading = false;

	async executeLoadData(){
		try{
			this.isLoading = true;
			console.log("loading modules....");
			const responseData = await storeFactory.ModuleStore.loadModules();
			runInAction("Home - Execute Load Data - Post Load Modules",() => {
				storeFactory.ModuleStore.SupportNumber = responseData.data.SUPPORT_NUMBER;
				storeFactory.ModuleStore.processModuleResponseData(responseData.data.TRANSLATIONS, responseData.data.MODULES);
				moduleContainerFactory.mapModuleToComponent(storeFactory.ModuleStore.allModuleList);
				if(storeFactory.ModuleStore.moduleList.length > 0){
					const module = _filter(storeFactory.ModuleStore.moduleList, "selected")[0];
					if(storeFactory.NavigationStore.currentModuleName === ""){
						if(module) {
							storeFactory.NavigationStore.changeModule(module.uid);
							storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.HOME);
						}
					}
				}
			});
		}
		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't Load Data", "On Home store load data");
		}
		finally {
			this.isLoading = false;	
		}	
	}

	async executeSendInvite(recipients){
		try{
			this.isLoading = true;
			let param = {};
			param.CONTACTS = recipients;
			await baby.post('/invite', param);
		}
		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't Send Invite", "On Home Send Invite");
		}
		finally {
			this.isLoading = false;	
		}	
	}
}

export default new HomeStore(); 