import { inject, observer } from 'mobx-react';
import React from 'react';
import ItemContainer from '../../0-common/container/ItemContainer';
import ProductContainer from '../container/module-container/ProductContainer';
import CollectionContainer from '../container/module-container/CollectionContainer';
import CatalogContainer from '../container/module-container/CatalogContainer';
import ShopContainer from '../container/module-container/ShopContainer';
import OrderContainer from '../container/module-container/OrderContainer';
import CustomerContainer from '../container/module-container/CustomerContainer';
import _assign from 'lodash/assign';

const _module_Repository_Map = {
	"PRODUCTS" 		: {
		component : ProductContainer,
		showCreateAction  : true
	},
	"COLLECTIONS" 	: {
		component : CollectionContainer,
		showCreateAction  : true
	},
	"CATALOGS" 		: {
		component : CatalogContainer,
		showCreateAction  : true
	},
	"WEBSHOPS" 		: {
		component : ShopContainer,
		showCreateAction  : true
	},
	"ORDERS"		: {
		component : OrderContainer,
		showCreateAction  : false
	},
	"CUSTOMERS"  : {
		component : CustomerContainer,
		showCreateAction  : true
	}
};


export class ModuleContainerFactory {

	getContainer(module){
		const moduleName = module.uid;
		const itemContainer = _module_Repository_Map[moduleName];
		if(itemContainer){
			return itemContainer;

		} else if(module.IsVisible){

			console.log("Preparing Generic Container for : "+moduleName);
			let moduleContainer = class extends ItemContainer{
				
				constructor(props){
					super(props, moduleName);
					this.openOnDBClick=true;
				}

				get CommonActions(){
					return ['SEARCH', 'FILTER'];
				}

				get SpecialActions(){
					return [];
				}
 
				get ItemActions(){
					return ['SELECT', 'DELETE'];
				}

				get BulkActions(){
					return ['DELETE'];
				}

				get ItemsPerRow(){
					return 3;
				}	

				get SelectOnClick(){
					return false;
				}

				componentWillUnmount(){
					this.unBindEvents();
				}

				componentDidMount() {
					this.fetchListViewData();
					this.resetStatus();
				}

				renderEmptyView(context){
				return null;
			}	

				render() {
					return this.renderView();
				}
			};

			return inject('createStore', 'storeFactory')(observer(moduleContainer));
		}
	}

	mapModuleToComponent(moduleList){
		moduleList.map( module => {
			const factoryModule = this.getContainer(module);
			_assign(module, factoryModule);
		});
	}
}

export default new ModuleContainerFactory();