import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Modal } from "semantic-ui-react";
import ItemContainer from '../../../0-common/container/ItemContainer';
import { value } from '../../../0-common/store/LanguageStore';

@inject('createStore', 'storeFactory')
@observer
export default class AddItemContainer extends ItemContainer {
	
	//STORE_CONTEXT_NAME = "AddItemContainer";

	constructor(props){

		var STORE_SUBJECT_TYPE = "";
		let AddItemContainer_STORE_SUBJECT_TYPE = null;
		
		if(props.storeType.Type){ //case for card predicates "add existing"
			AddItemContainer_STORE_SUBJECT_TYPE = props.storeType;
			STORE_SUBJECT_TYPE = props.storeType.Type;
		} else { //case for "add to collection"
			STORE_SUBJECT_TYPE = props.storeType;
		}

		super(props, STORE_SUBJECT_TYPE, AddItemContainer_STORE_SUBJECT_TYPE);
		
		this.STORE_CONTEXT = AddItemContainer_STORE_SUBJECT_TYPE ? AddItemContainer_STORE_SUBJECT_TYPE : STORE_SUBJECT_TYPE;
		this.STORE_TYPE = STORE_SUBJECT_TYPE;
		this.openOnDBClick = false;
	}

	@computed
	get isItemSelectedForAdd(){
		return this.itemStore.selectedCount > 0 ? true : false;
	}

	get CommonActions(){
		return ['SEARCH+', 'FILTER'];
	}

	get SpecialActions(){
		return ["SELECT ALL", "DESELECT ALL"];
	}

	get ItemActions(){
		return []; // 'SELECT' is not added instead 'SelectOnClick' below is used, exclusively for AddItemContainer
	}

	get BulkActions(){
		return [];
	}

	get ItemsPerRow(){
		return 4;
	}

	//'SELECT' is not added in 'ItemActions' exclusively for AddItemContainer.
	// instead use this.
	get SelectOnClick(){
		return true;
	}

	get ShowCollageView(){
		if(this.STORE_TYPE === "COLLECTIONS" || this.STORE_TYPE === "CATALOGS")
			return true;
		else 
			return false;
	}

	isContainerEmpty = () => {
		const itemsList = this.itemStore.ItemList;
		if( !this.itemStore.isLoading && itemsList && itemsList.length === 0){
			return true;
		}
		return false;
	}

	buildImageURLsForCollageView(item){
		let imgRefField = "";
		if(this.STORE_TYPE === "COLLECTIONS"){
			if(!item.DATA.ProfileImage){
				imgRefField = "ProductsInCollection"
			}else{//IF ProfileImage is set for collection then don't return collage view urls.
				return null;
			}
		}
		if(this.STORE_TYPE === "CATALOGS"){
			imgRefField = "ProductsInCatalog";
		}
		const productsIn = item.DATA[imgRefField];
		const imageURLs = _map(productsIn, (productId) => {
			const imageId = item.REFERENCES[productId].ProfileImage;
			if(imageId && imageId !== ""){
				return item.REFERENCE_IMAGES[imageId].SourceID;
			}
		});
		return _compact(imageURLs);
	}

	componentWillUnmount(){
		this.unBindEvents();
		this.props.storeFactory.removeStore(this.STORE_CONTEXT);
	}

	componentDidMount() {
		this.fetchListViewData();
	}

	render() {
		return	(
			<Modal
				centered
		    	size='large'
		    	className="addItemDialog"
				closeOnDimmerClick={false}
			    open={true}>
				    <Modal.Content>
				    	{this.renderView()}
				    </Modal.Content>
				    <Modal.Actions>
				    	<Button 
				    		primary 
				    		icon='add' 
				    		content={value('bt_additem')} 
				    		onClick={this.props.onAdd} 
				    		disabled={!this.isItemSelectedForAdd} />
				      	<Button 
				      		icon='cancel' 
				      		content={value('bt_close')} 
				      		onClick={this.props.onCancel} />
				    </Modal.Actions>
			</Modal>
		);
	}
}